<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('tradeTariffHsCode.hs_code')}} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
          <b-row>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Section Number" vid="section_id" rules="">
                  <b-form-group
                    label-for="section_id">
                    <template v-slot:label>
                      {{ $t('tradeTariffConfig.section_no') }}
                    </template>
                    <b-form-select
                      plain
                      id="section_id"
                      :options="sectionList"
                      v-model="search.section_id"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Chapter Number" vid="chapter_id" rules="">
                  <b-form-group
                    label-for="chapter_id">
                    <template v-slot:label>
                      {{ $t('tradeTariffConfig.chapter_no') }}
                    </template>
                    <b-form-select
                      plain
                      id="chapter_id"
                      :options="chapterList"
                      v-model="search.chapter_id"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Heading Number" vid="heading_id" rules="">
                  <b-form-group
                    label-for="heading_id">
                    <template v-slot:label>
                      {{ $t('tradeTariffConfig.heading_no') }}
                    </template>
                    <b-form-select
                      plain
                      id="heading_id"
                      :options="headingList"
                      v-model="search.heading_id"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Sub Heading Number" vid="sub_heading_id" rules="">
                  <b-form-group
                    label-for="sub_heading_id">
                    <template v-slot:label>
                      {{ $t('tradeTariffConfig.sub_heading_no') }}
                    </template>
                    <b-form-select
                      plain
                      id="sub_heading_id"
                      :options="subHeadingList"
                      v-model="search.sub_heading_id"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="8" lg="8" xl="8">
              </b-col>
              <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <div class="row">
                    <b-col xs="6" sm="6" md="6">
                  <ValidationProvider name="Search by" vid="search_by" rules="">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="search_by"
                      >
                      <template v-slot:label>
                        {{$t('externalTradeTraiff.search_by')}}
                      </template>
                      <b-form-radio-group
                        id="search_by"
                        v-model="search.search_by"
                        :options="searchByList"
                        name="search_by"
                        @input="handleSearchByChange"
                      ></b-form-radio-group>
                      </b-form-group>
                  </ValidationProvider>
              </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Description" vid="description" rules="">
                        <b-form-group
                          label-for="description">
                          <b-form-input
                            id="description"
                            v-model="search.description"
                            :placeholder="descPlac"
                          ></b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                  </div>
              </b-col>
            <b-col xs="12" sm="12" md="4" v-if="search.search_by === 1 || search.search_by === 2">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-for="fiscal_year_id"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.fiscal_year') }} <span class="text-danger"> *</span>
                </template>
                <b-form-select
                  plain
                  id="fiscal_year_id"
                  :options="fiscalYearList"
                  v-model="search.fiscal_year_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
            </b-col>
              <b-col sm="9">
              </b-col>
              <b-col sm="3" style="text-align: right;">
                <b-button size="sm" type="button" @click="resetSearch()" variant="warning" class="mt-20 mx-2">
                  <i class="ri-refresh-line"></i> {{ $t('globalTrans.reset') }}
                </b-button>
                <b-button size="sm" type="submit" variant="primary" class="mt-20">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('tradeTariffHsCode.hs_code') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
              <div class="text-center p-2">
                <h4 v-if="searchType === 1">{{getSection(search.section_id)}}; {{getSectionName(search.section_id)}}</h4>
                <h4 v-if="searchType === 2">{{getChapter(search.chapter_id)}}; {{getChapterName(search.chapter_id)}}</h4>
                <h4 v-if="searchType === 3">{{getHeading(search.heading_id)}}; {{getHeadingName(search.chapter_id)}}</h4>
              </div>
              <template v-if="searchType === 1">
                <table class="table table-bordered" style="width: 100%; padding:10px">
                <thead>
                  <tr>
                    <th>{{$t('externalTradeTraiff.chapter_codes')}}</th>
                    <th>{{$t('externalTradeTraiff.chapter_description')}}</th>
                    <th>{{$t('externalTradeTraiff.custom_duty')}}</th>
                  </tr>
                </thead>
                <tbody>
                    <template v-if="dataList.length > 0">
                        <tr v-for="(chapter, index) in dataList" :key="index">
                          <td>{{ getChapter(chapter.id) }}</td>
                          <td>{{ chapter.chapter_name_en }}</td>
                          <td>
                            <b-button v-b-modal.modal-hs-code @click="hsCodes(chapter.hs_code, 1)" class="btn btn-success btn-md mx-1">{{$t('externalTradeTraiff.import_duty')}}</b-button>
                            <b-button v-b-modal.modal-hs-code @click="hsCodes(chapter.hs_code, 2)" class="btn btn-info btn-md">{{$t('externalTradeTraiff.export_duty')}}</b-button>
                          </td>
                        </tr>
                    </template>
                      <template v-else>
                        <div class="text-center">
                          {{$t('globalTrans.noDataFound')}}
                        </div>
                      </template>
                </tbody>
              </table>
              </template>
              <template v-if="searchType === 2">
                <table class="table table-bordered" style="width: 100%; padding:10px">
                <thead>
                  <tr>
                    <th>{{$t('externalTradeTraiff.heading_codes')}}</th>
                    <th>{{$t('externalTradeTraiff.heading_description')}}</th>
                    <th>{{$t('externalTradeTraiff.custom_duty')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="dataList.length > 0">
                    <tr v-for="(heading, index) in dataList" :key="index">
                      <td>{{ getHeading(heading.id) }}</td>
                      <td>{{ heading.heading_name_en }}</td>
                      <td>
                        <b-button v-b-modal.modal-hs-code @click="hsCodes(heading.hs_code, 1)" class="btn btn-success btn-md mx-1">{{$t('externalTradeTraiff.import_duty')}}</b-button>
                        <b-button v-b-modal.modal-hs-code @click="hsCodes(heading.hs_code, 2)" class="btn btn-info btn-md">{{$t('externalTradeTraiff.export_duty')}}</b-button>
                      </td>
                    </tr>
                    </template>
                      <template v-else>
                        <div class="text-center">
                          {{$t('globalTrans.noDataFound')}}
                        </div>
                      </template>
                </tbody>
              </table>
              </template>
              <template v-if="searchType === 3">
                <table class="table table-bordered" style="width: 100%; padding:10px">
                <thead>
                  <tr>
                    <th>{{$t('externalTradeTraiff.sub_heading_codes')}}</th>
                    <th>{{$t('externalTradeTraiff.sub_heading_description')}}</th>
                    <th>{{$t('externalTradeTraiff.custom_duty')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="dataList.length > 0">
                    <tr v-for="(subHeading, index) in dataList" :key="index">
                      <td>{{ getSubHeading(subHeading.id) }}</td>
                      <td>{{ subHeading.sub_heading_name_en }}</td>
                      <td>
                        <b-button v-b-modal.modal-hs-code @click="hsCodes(subHeading.hs_code, 1)" class="btn btn-success btn-md mx-1">{{$t('externalTradeTraiff.import_duty')}}</b-button>
                        <b-button v-b-modal.modal-hs-code @click="hsCodes(subHeading.hs_code, 2)" class="btn btn-info btn-md">{{$t('externalTradeTraiff.export_duty')}}</b-button>
                      </td>
                    </tr>
                    </template>
                    <template v-else>
                      <div class="text-center">
                        {{$t('globalTrans.noDataFound')}}
                      </div>
                    </template>
                </tbody>
              </table>
              </template>
            <div v-if="searchType === 4">
                <table class="table table-bordered" style="width: 100%; padding:10px">
                  <thead>
                    <tr>
                      <th>{{$t('tradeTariffHsCode.hs_code')}}</th>
                      <th>{{$t('externalTradeTraiff.item_description')}}</th>
                      <th>{{$t('tradeTariffHsCode.import_duty')}}</th>
                      <th>{{$t('tradeTariffHsCode.export_duty')}}</th>
                      <th>{{$t('tradeTariffHsCode.import')}} {{$t('globalTrans.attachment')}}</th>
                      <th>{{$t('tradeTariffHsCode.export')}} {{$t('globalTrans.attachment')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                        <td>{{ hsCode.hs_code }}</td>
                        <td>{{ hsCode.description }}</td>
                        <td>{{ hsCode.import_duty }}</td>
                        <td >{{ hsCode.export_duty }}</td>
                        <td>
                            <span v-if="hsCode.import_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                            :href="tradeTariffServiceBaseUrl+'download-attachment?file='+hsCode.import_attachment"
                                            title="Import Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                <span v-else>N/A</span>
                        </td>
                        <td>
                            <span v-if="hsCode.export_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                            :href="tradeTariffServiceBaseUrl+'download-attachment?file='+hsCode.export_attachment"
                                            title="Export Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                <span v-else>N/A</span>
                        </td>
                      </tr>
                  </tbody>
                </table>
            </div>
            <div v-if="searchType === 5 || searchType === 6">
                <table class="table table-bordered" style="width: 100%; padding:10px">
                  <thead>
                    <tr>
                      <th>{{$t('tradeTariffHsCode.hs_code')}}</th>
                      <th>{{$t('externalTradeTraiff.item_description')}}</th>
                      <th>{{$t('tradeTariffHsCode.import_duty')}}</th>
                      <th>{{$t('tradeTariffHsCode.export_duty')}}</th>
                      <th>{{$t('tradeTariffHsCode.import')}} {{$t('globalTrans.attachment')}}</th>
                      <th>{{$t('tradeTariffHsCode.export')}} {{$t('globalTrans.attachment')}}</th>
                      <th>{{$t('globalTrans.action')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                      <template v-if="dataList.length > 0">
                        <tr v-for="(hsCode, index) in dataList" :key="index">
                        <td>{{ hsCode.hs_code }}</td>
                        <td>{{ hsCode.description }}</td>
                        <td>{{ hsCode.import_duty }}</td>
                        <td >{{ hsCode.export_duty }}</td>
                        <td>
                            <span v-if="hsCode.import_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                            :href="tradeTariffServiceBaseUrl+'download-attachment?file='+hsCode.import_attachment"
                                            title="Import Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                <span v-else>N/A</span>
                        </td>
                        <td>
                            <span v-if="hsCode.export_attachment !== null"><a target="_blank" class="btn btn-primary mr-2"
                                            :href="tradeTariffServiceBaseUrl+'download-attachment?file='+hsCode.export_attachment"
                                            title="Export Attachment"><i class="ri-file-download-line"></i>&nbsp;</a></span>
                                <span v-else>N/A</span>
                        </td>
                        <td>
                          <b-button v-b-modal.modal-details variant=" iq-bg-success mr-1" size="sm" @click="details(hsCode)" class="action-btn edit" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                        </td>
                     </tr>
                      </template>
                      <template v-else>
                        <div class="text-center">
                          {{$t('globalTrans.noDataFound')}}
                        </div>
                      </template>
                  </tbody>
                </table>
            </div>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-hs-code" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <HsCodeDetails :hsCodeItems="items" :importOrExport="importOrExport"/>
    </b-modal>
    <b-modal id="modal-details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
  </div>
</template>
<style scoped>
th {
    background-color: #007bff;
    color: white;
}
</style>
<script>
import Details from './Details.vue'
import HsCodeDetails from './HsCodeDetails.vue'
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { searchHsCode } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    HsCodeDetails, Details
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        fiscal_year_id: 0,
        section_id: 0,
        chapter_id: 0,
        heading_id: 0,
        sub_heading_id: 0,
        search_by: 0,
        description: '',
        limit: 10
      },
      descPlac: this.$i18n.locale === 'en' ? 'Enter Description' : 'বর্ণনা লিখুন',
      hsCode: '',
      viewitemId: 0,
      dataList: [],
      chapterList: [],
      headingList: [],
      subHeadingList: [],
      searchType: 0,
      importOrExport: 0,
      items: []
    }
  },
  created () {
    // this.loadData()
  },
  computed: {
    searchByList: function () {
      const list = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'HS Code' : 'এইচএস কোড' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Product Name' : 'পণ্যের নাম' }
            ]
           return list
    },
    currentLocale () {
      return this.$i18n.locale
    },
    sectionList () {
      return this.$store.state.TradeTariffService.commonObj.sectionNoList.filter(item => item.status === 1)
    },
    viewTitle () {
      return this.$t('tradeTariffHsCode.hs_code') + ' ' + this.$t('globalTrans.details')
    },
    viewTitle2 () {
      return this.$t('tradeTariffHsCode.hs_code') + ' ' + this.$t('globalTrans.details')
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
       if (newVal !== oldVal) {
            this.localizeList(newVal)
        }
    },
    'search.section_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.chapterList = this.getChapterList(newVal)
      } else {
        this.chapterList = []
      }
    },
    'search.chapter_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.headingList = this.getHeadingList(newVal)
      } else {
        this.headingList = []
      }
    },
    'search.heading_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.subHeadingList = this.getSubHeadingList(newVal)
      } else {
        this.subHeadingList = []
      }
    }
  },
  methods: {
    localizeList (locale) {
          return this.searchByList.map(item => {
                return Object.assign({}, item, {
                    text: locale === 'en' ? item.text_en : item.text_bn
                })
          })
      },
     resetSearch () {
        this.search = {
        section_id: 0,
        chapter_id: 0,
        heading_id: 0,
        sub_heading_id: 0,
        search_by: 0,
        description: '',
        limit: 10
      }
     },
    //  searchByChange () {
    //   if (this.search.search_by === 1) {

    //   }
    //   if (this.search.search_by === 2) {

    //   }
    // },
    hsCodes (items, importOrExport) {
      this.items = items
      this.importOrExport = importOrExport
    },
    details (items) {
      this.items = items
      this.viewitemId = items.id
    },
    getChapterList (id) {
      return this.$store.state.TradeTariffService.commonObj.chapterNoList.filter(item => item.status === 1 && item.section_id === id)
    },
    getHeadingList (id) {
      return this.$store.state.TradeTariffService.commonObj.headingList.filter(item => item.status === 1 && item.chapter_id === id)
    },
    getSubHeadingList (id) {
      return this.$store.state.TradeTariffService.commonObj.subHeadingList.filter(item => item.status === 1 && item.heading_id === id)
    },
     getSection (secId) {
          const cateObj = this.$store.state.TradeTariffService.commonObj.sectionNoList.filter(item => item.status === 1).find(item => item.value === secId)
          if (cateObj !== undefined) {
            return cateObj.text_en
          }
     },
     getSectionName (secId) {
          const cateObj = this.$store.state.TradeTariffService.commonObj.sectionNoList.filter(item => item.status === 1).find(item => item.value === secId)
          if (cateObj !== undefined) {
            return cateObj.section_name_en
          }
     },
     getChapter (chapId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.chapterNoList.filter(item => item.status === 1).find(item => item.value === chapId)
            if (cateObj !== undefined) {
              return cateObj.text_en
            }
     },
     getChapterName (chapId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.chapterNoList.filter(item => item.status === 1).find(item => item.value === chapId)
            if (cateObj !== undefined) {
              return cateObj.chapter_name_en
            }
     },
     getHeading (headId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.headingList.filter(item => item.status === 1).find(item => item.value === headId)
            if (cateObj !== undefined) {
              return cateObj.text_en
            }
     },
     getHeadingName (headId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.headingList.filter(item => item.status === 1).find(item => item.value === headId)
            if (cateObj !== undefined) {
              return cateObj.heading_name_en
            }
     },
     getSubHeading (headId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.subHeadingList.filter(item => item.status === 1).find(item => item.value === headId)
            if (cateObj !== undefined) {
              return cateObj.text_en
            }
     },
     getSubHeadingName (headId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.subHeadingList.filter(item => item.status === 1).find(item => item.value === headId)
            if (cateObj !== undefined) {
              return cateObj.sub_headning_name_en
            }
     },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.dataList = []
      this.searchType = 0
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, searchHsCode, params)
      if (result.success) {
        this.dataList = result.data
        this.searchType = result.searchType
        if (result.searchType === 4) {
          this.hsCode = result.data
        }
      } else {
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    handleSearchByChange () {
      this.search.fiscal_year_id = 0
    }
  }
}
</script>
