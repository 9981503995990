<template>
  <b-container fluid>
       <b-row style="line-height: 20px">
            <template>
                <b-row>
                    <b-col md="12">
                        <table style="line-height: 25px" class="table table-sm table-borderless">
                            <tr>
                                <th style="width: 20%">{{$t('tradeTariffConfig.section_no')}} </th>
                                <td style="width: 35%">: {{ getSectionName(items.section_id)}}</td>
                                <th style="width: 20%">{{$t('tradeTariffConfig.chapter_no')}}</th>
                                <td style="width: 35%"> : {{ getChapterName(items.chapter_id) }} </td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{$t('tradeTariffConfig.heading_no')}} </th>
                                <td style="width: 35%">: {{ getHeadingName(items.heading_id)}}</td>
                                <th style="width: 20%">{{$t('tradeTariffConfig.sub_heading_no')}}</th>
                                <td style="width: 35%"> : {{ getSubHeadingName(items.sub_heading_id) }} </td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{$t('tradeTariffHsCode.hs_code')}}</th>
                                <td style="width: 35%" >: {{items.hs_code}}</td>
                                <th style="width: 20%">{{$t('globalTrans.fiscal_year')}}</th>
                                <td style="width: 35%">: {{ getFiscalYear(items.fiscal_year_id )}}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{ $t('tradeTariffHsCode.su')}} </th>
                                <td style="width: 35%">: {{ items.su }}</td>
                                <th style="width: 20%">{{ $t('tradeTariffHsCode.cd')}}</th>
                                <td style="width: 35%">: {{ items.cd }}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{ $t('tradeTariffHsCode.rd')}} </th>
                                <td style="width: 35%">: {{ items.rd }}</td>
                                <th style="width: 20%">{{ $t('tradeTariffHsCode.sd')}}</th>
                                <td style="width: 35%">: {{ items.sd }}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{ $t('tradeTariffHsCode.ait')}} </th>
                                <td style="width: 35%">: {{ items.ait }}</td>
                                <th style="width: 20%">{{ $t('tradeTariffHsCode.atv')}}</th>
                                <td style="width: 35%">: {{ items.atv }}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{ $t('tradeTariffHsCode.tti')}} </th>
                                <td style="width: 35%">: {{ items.tti }}</td>
                                <th style="width: 20%">{{ $t('tradeTariffHsCode.vat')}}</th>
                                <td style="width: 35%">: {{ items.vat }}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{ $t('tradeTariffHsCode.sro_reference')}} </th>
                                <td style="width: 35%">: {{ items.sro_reference }}</td>
                            </tr>
                            <tr>
                                <th style="width: 20%">{{$t('eBizConfig.description')}}</th>
                                <td colspan="3" style="width: 85%">: {{ items.description }} </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
            </template>
    </b-row>
  </b-container>
</template>
<script>
import { tradeTariffServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['items'],
    components: {
    },
    created () {
    },
    mounted () {
        // core.index()
    },
    data () {
        return {
              tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
     getSectionName (secId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.sectionNoList.filter(item => item.status === 1).find(item => item.value === secId)
            if (cateObj !== undefined) {
                return cateObj.text_en
            }
     },
     getChapterName (chapId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.chapterNoList.filter(item => item.status === 1).find(item => item.value === chapId)
            if (cateObj !== undefined) {
                return cateObj.text_en
            }
     },
     getHeadingName (headId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.headingList.filter(item => item.status === 1).find(item => item.value === headId)
            if (cateObj !== undefined) {
                return cateObj.text_en
            }
     },
     getSubHeadingName (headId) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.subHeadingList.filter(item => item.status === 1).find(item => item.value === headId)
            if (cateObj !== undefined) {
                return cateObj.text_en
            }
     },
     getFiscalYear (id) {
        const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
        if (typeof data !== 'undefined') {
          return data.text_en
        } else {
          return ''
        }
      }
    }
}

</script>
